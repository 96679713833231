import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../components/Navigation';

const PatientForm = () => {
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState({
    geburtsdatum: '',
    geschlecht: '',
    ethnie: '',
  });
  const [error, setError] = useState('');
  const [bisherigeTherapie, setBisherigeTherapie] = useState({
    lokaleTherapie: [],
    systemtherapie: [],
  });
  const [bisherigeTherapieSonstiges, setBisherigeTherapieSonstiges] = useState({
    lokaleTherapie: '',
    systemtherapie: '',
  });

  useEffect(() => {
    const fetchTherapyOptions = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const lokaleResponse = await axios.get(
          'https://core.ck-care.ch/api/therapy/lokale',
          { headers }
        );
        const systemtherapieResponse = await axios.get(
          'https://core.ck-care.ch/api/therapy/systemtherapie',
          { headers }
        );

        const mapOptions = options =>
          options.map(option => ({
            ...option,
            selected: false,
          }));

        setBisherigeTherapie({
          lokaleTherapie: mapOptions(lokaleResponse.data),
          systemtherapie: mapOptions(systemtherapieResponse.data),
        });

      } catch (error) {
        setError('Error fetching therapy options.');
      }
    };

    fetchTherapyOptions();
  }, []);

  const handleChange = e => {
    setPatientData({ ...patientData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (optionType, optionId, isAktuelle = false) => {
    const therapyOptionsToUpdate = bisherigeTherapie;
    const updatedTherapyOptions = { ...therapyOptionsToUpdate };

    const selectedOption = updatedTherapyOptions[optionType].find(
      option => option.id === optionId
    );
    if (selectedOption) {
      selectedOption.selected = !selectedOption.selected;
    }
      setBisherigeTherapie(updatedTherapyOptions);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const validateForm = () => {
    for (const [key, value] of Object.entries(patientData)) {
      if (value === '') {
        setError('Bitte füllen Sie alle Felder aus.');
        return false;
      }
    }
    setError('');
    return true;
  };

  const updateTherapieData = async (patientId, therapieData, endpoint) => {
    const token = localStorage.getItem('token');
    await axios.post(
      `https://core.ck-care.ch/api/${endpoint}/${patientId}`,
      therapieData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const doctorId = localStorage.getItem('userId');
    const patientPayload = {
      ...patientData,
      doctor_id: doctorId,
      bisherige_lokaltherapie_sonstiges:
        bisherigeTherapieSonstiges.lokaleTherapie,
      bisherige_systemtherapie_sonstiges:
        bisherigeTherapieSonstiges.systemtherapie,
    };

    try {
      const token = localStorage.getItem('token');
      const createResponse = await axios.post(
        'https://core.ck-care.ch/api/patient',
        patientPayload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const patientId = createResponse.data.patientId;

        const createKerndatenBisherResponse = await axios.post(
            `https://core.ck-care.ch/api/patient-bisherige-kerndaten/${patientId}`,
            patientPayload,
            {
            headers: { Authorization: `Bearer ${token}` },
            }
        );

      const processTherapieOptions = async (
        lokaleTherapie,
        systemtherapie,
        patientId,
        endpoint
      ) => {
        const lokaleTherapieIds = lokaleTherapie
          .filter(option => option.selected)
          .map(option => option.id);
        const systemtherapieIds = systemtherapie
          .filter(option => option.selected)
          .map(option => option.id);

        const therapieData = {
          lokaleTherapie: lokaleTherapieIds,
          systemtherapie: systemtherapieIds,
        };

        if (lokaleTherapieIds.length > 0 || systemtherapieIds.length > 0) {
          await updateTherapieData(patientId, therapieData, endpoint);
        }
      };

      await processTherapieOptions(
        bisherigeTherapie.lokaleTherapie,
        bisherigeTherapie.systemtherapie,
        patientId,
        'patient-bisherige-therapien'
      );

      navigate('/dashboard');
    } catch (error) {
      setError(error.response?.data?.message || 'Ein Fehler ist aufgetreten.');
    }
  };

  return (
    <>
      <NavBar />
      <div className="container mt-5">
        <h1>Patienten registrieren</h1>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="form-group mb-3">
            <label htmlFor="geburtsdatum">Geburtsdatum</label>
            <input
              type="date"
              className="form-control"
              id="geburtsdatum"
              name="geburtsdatum"
              value={patientData.geburtsdatum}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="geschlecht">Geschlecht</label>
            <select
              className="form-control"
              id="geschlecht"
              name="geschlecht"
              value={patientData.geschlecht}
              onChange={handleChange}
            >
              <option value="">Geschlecht auswählen</option>
              <option value="Männlich">Männlich</option>
              <option value="Weiblich">Weiblich</option>
              <option value="Divers">Divers</option>
            </select>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="ethnie">Ethnie</label>
            <input
              type="text"
              className="form-control"
              id="ethnie"
              name="ethnie"
              value={patientData.ethnie}
              onChange={handleChange}
            />
          </div>

            <h2>Bisherige Patientendaten erfassen</h2>
            <p>Bitte erfassen Sie die bisherigen Therapien. Die aktuellen Therapien können Sie vor jeder Scoreberechnung erfassen.</p>

          <div className="form-group mb-3">
            <label htmlFor="bisherige_lokaltherapie">
              Bisherige lokale Therapie
            </label>
            {bisherigeTherapie.lokaleTherapie.map(option => (
              <div key={option.id} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`bisherige_lokaltherapie_${option.id}`}
                  checked={option.selected}
                  onChange={() =>
                    handleCheckboxChange('lokaleTherapie', option.id)
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor={`bisherige_lokaltherapie_${option.id}`}
                >
                  {option.option_name}
                </label>
              </div>
            ))}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="bisherige_lokaltherapie_sonstiges_checkbox"
                onChange={e =>
                  setBisherigeTherapieSonstiges({
                    ...bisherigeTherapieSonstiges,
                    showLokale: e.target.checked,
                  })
                }
              />
              <label
                className="form-check-label"
                htmlFor="bisherige_lokaltherapie_sonstiges_checkbox"
              >
                Sonstiges
              </label>
            </div>
            {bisherigeTherapieSonstiges.showLokale && (
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Sonstiges"
                value={bisherigeTherapieSonstiges.lokaleTherapie}
                onChange={e =>
                  setBisherigeTherapieSonstiges({
                    ...bisherigeTherapieSonstiges,
                    lokaleTherapie: e.target.value,
                  })
                }
              />
            )}
          </div>

          <div className="form-group mb-3">
            <label htmlFor="bisherige_systemtherapie">
              Bisherige systemische Therapie
            </label>
            {bisherigeTherapie.systemtherapie.map(option => (
              <div key={option.id} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`bisherige_systemtherapie_${option.id}`}
                  checked={option.selected}
                  onChange={() =>
                    handleCheckboxChange('systemtherapie', option.id)
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor={`bisherige_systemtherapie_${option.id}`}
                >
                  {option.option_name}
                </label>
              </div>
            ))}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="bisherige_systemtherapie_sonstiges_checkbox"
                onChange={e =>
                  setBisherigeTherapieSonstiges({
                    ...bisherigeTherapieSonstiges,
                    showSystem: e.target.checked,
                  })
                }
              />
              <label
                className="form-check-label"
                htmlFor="bisherige_systemtherapie_sonstiges_checkbox"
              >
                Sonstiges
              </label>
            </div>
            {bisherigeTherapieSonstiges.showSystem && (
              <input
                type="text"
                className="form-control mt-2"
                placeholder="Sonstiges"
                value={bisherigeTherapieSonstiges.systemtherapie}
                onChange={e =>
                  setBisherigeTherapieSonstiges({
                    ...bisherigeTherapieSonstiges,
                    systemtherapie: e.target.value,
                  })
                }
              />
            )}
          </div>

          <button type="submit" className="btn btn-link mb-3">
            Patientendaten speichern
          </button>
          <br />
          <button
            type="button"
            className="btn btn-link mb-3"
            onClick={handleBack}
          >
            Zurück
          </button>
        </form>
      </div>
    </>
  );
};

export default PatientForm;
