import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';

const AdminDashboard = () => {
    const [scores, setScores] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [patients, setPatients] = useState([]);
    const [kernAktuell, setKernAktuell] = useState([]);
    const [kernBisher, setKernBisher] = useState([]);
    const [aktuellLokal, setAktuellLokal] = useState([]);
    const [aktuellSystem, setAktuellSystem] = useState([]);
    const [bisherigLokal, setBisherigLokal] = useState([]);
    const [bisherigSystem, setBisherigSystem] = useState([]);
    const [patientScores, setPatientScores] = useState([]);

  const handleExport = async () => {
    const adminToken = localStorage.getItem('adminToken');

    try {
      const response = await axios.get(
        'https://core.ck-care.ch/api/admin/export',
        {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
          responseType: 'blob',
        }
      );

      const utf8BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([utf8BOM, response.data], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(blob, 'patienten_daten.csv');
    } catch (error) {
      console.error('Error during export:', error);
    }
    
  };
    const getData = async data => {
        const adminToken = localStorage.getItem('adminToken');
        try {
            const response = await axios.get(
                // variable content
                `https://core.ck-care.ch/api/admin/${data}`,
            {
                headers: {
                Authorization: `Bearer ${adminToken}`,
                },
                responseType: 'blob',
            }
            );
            

            const utf8BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
            const blob = new Blob([utf8BOM, response.data], {
                    type: 'text/csv;charset=utf-8',
            });
            // variable content
            saveAs(blob, `${data.replace('export', 'export_')}.csv`);
        } catch (error) {
            console.error('Error during export:', error);
        }
    };

    useEffect(() => {
        const getDisplayData = async (data, func) => {
            const adminToken = localStorage.getItem('adminToken');
            try {
                const response = await axios.get(
                    `https://core.ck-care.ch/api/admin/${data}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${adminToken}`,
                        }
                    }
                );
                if(response.data.status === 'success') {
                    func(response.data.data);
                }
            } catch (error) {
                console.error('Error during export:', error);
            }

        };
        getDisplayData('displayscores', setScores);
        getDisplayData('displayadmins', setAdmins);
        getDisplayData('displaydoctors', setDoctors);
        getDisplayData('displaypatients', setPatients);
        getDisplayData('displaykerndatenaktuell', setKernAktuell);
        getDisplayData('displaykerndatenbisher', setKernBisher);
        getDisplayData('displayaktuellelokale', setAktuellLokal);
        getDisplayData('displayaktuellesystem', setAktuellSystem);
        getDisplayData('displaybisherigelokale', setBisherigLokal);
        getDisplayData('displaybisherigesystem', setBisherigSystem);
        getDisplayData('displaypatientscores', setPatientScores);
    }, []);

    return (
        <div style={{marginBottom: '50px', marginTop: '20px'}}>
            <h1 style={{textAlign: 'center'}}>Datenexporte</h1>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{marginBottom: '25px'}}
            >
                <button onClick={handleExport} className="btn btn-link">
                    Zusammengefasste Ergebnisse exportieren
                </button>
            </div>
            <hr />
            <h2 style={{textAlign: 'center', marginTop: '20px'}}>Admins</h2>
            <div
                className="d-flex justify-content-center align-items-center"
            >
                <button onClick={() => getData('exportadmins')} className="btn btn-link">
                    Admins exportieren
                </button>
            </div>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{flexWrap: 'wrap', columnGap: '20px'}}
            >
                <p style={{marginBottom: '0px'}}>ID</p>
                <p style={{marginBottom: '0px'}}>E-Mail</p>
            </div>
            {
                admins ? admins.map((admin, index) => 
                    (
                        <div
                            key={admin.id}
                            className="d-flex justify-content-center align-items-center"
                            style={{flexWrap: 'wrap', columnGap: '20px'}}
                        >
                            <p style={{marginBottom: '0px'}}>{admin.id}</p>
                            <p style={{marginBottom: '0px'}}>{admin.email}</p>
                        </div>
                    )
                )
                :
                (
                    
                    <p style={{marginBottom: '0px'}}>Bislang sind keine Admins verfügbar.</p>
                )
            }
            <h2 style={{textAlign: 'center', marginTop: '20px'}}>Doktoren</h2>
            <div
                className="d-flex justify-content-center align-items-center"
            >
                <button onClick={() => getData('exportdoctors')} className="btn btn-link">
                    Doktoren exportieren
                </button>
            </div>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{flexWrap: 'wrap', columnGap: '20px'}}
            >
                <p style={{marginBottom: '0px'}}>ID</p>
                <p style={{marginBottom: '0px'}}>Anrede</p>
                <p style={{marginBottom: '0px'}}>Titel</p>
                <p style={{marginBottom: '0px'}}>Vorname</p>
                <p style={{marginBottom: '0px'}}>Nachname</p>
                <p style={{marginBottom: '0px'}}>E-Mail</p>
                <p style={{marginBottom: '0px'}}>Arbeitsstelle Name</p>
                <p style={{marginBottom: '0px'}}>Arbeitsstelle Adresse</p>
                <p style={{marginBottom: '0px'}}>Arbeitsstelle Stadt</p>
                <p style={{marginBottom: '0px'}}>Arbeitsstelle PLZ</p>
                <p style={{marginBottom: '0px'}}>Arbeitsstelle Land</p>
                <p style={{marginBottom: '0px'}}>Arbeitsstelle Tätigkeitsbereich</p>
                <p style={{marginBottom: '0px'}}>Arbeitsstelle Tätigkeitsbereich Sonstiges</p>
            </div>
            {
                doctors ? doctors.map((doctor, index) => 
                    (
                        <div
                            key={index}
                            className="d-flex justify-content-center align-items-center"
                            style={{flexWrap: 'wrap', columnGap: '20px'}}
                        >
                            <p style={{marginBottom: '0px'}}>{doctor.id}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.anrede}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.titel}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.vorname}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.nachname}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.email}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.arbeitsstelle_name}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.arbeitsstelle_adresse}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.arbeitsstelle_stadt}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.arbeitsstelle_plz}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.arbeitsstelle_land}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.arbeitsstelle_taetigkeitsbereich}</p>
                            <p style={{marginBottom: '0px'}}>{doctor.arbeitsstelle_taetigkeitsbereich_sonstiges}</p>
                        </div>
                    )
                )
                :
                (
                    
                    <p style={{marginBottom: '0px'}}>Bislang sind keine Doktoren verfügbar.</p>
                )
            }
            <h2 style={{textAlign: 'center', marginTop: '20px'}}>Patienten</h2>
            <div
            className="d-flex justify-content-center align-items-center"
            >
                <button onClick={() => getData('exportpatients')} className="btn btn-link">
                    Patienten exportieren
                </button>
            </div>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{flexWrap: 'wrap', columnGap: '20px'}}
            >
                <p style={{marginBottom: '0px'}}>Id</p>
                <p style={{marginBottom: '0px'}}>Patient-ID</p>
                <p style={{marginBottom: '0px'}}>Doctor-ID</p>
                <p style={{marginBottom: '0px'}}>Geburtsdatum</p>
                <p style={{marginBottom: '0px'}}>Geschlecht</p>
                <p style={{marginBottom: '0px'}}>Ethnie</p>
                <p style={{marginBottom: '0px'}}>Created at</p>
            </div>        
            {
                patients ? patients.map((patient, index) => 
                    (
                        <div
                            key={index}
                            className="d-flex justify-content-center align-items-center"
                            style={{flexWrap: 'wrap', columnGap: '20px'}}
                        >
                            <p style={{marginBottom: '0px'}}>{patient.id}</p>
                            <p style={{marginBottom: '0px'}}>{patient.patient_id}</p>
                            <p style={{marginBottom: '0px'}}>{patient.doctor_id}</p>
                            <p style={{marginBottom: '0px'}}>{patient.geburtsdatum}</p>
                            <p style={{marginBottom: '0px'}}>{patient.geschlecht}</p>
                            <p style={{marginBottom: '0px'}}>{patient.ethnie}</p>
                            <p style={{marginBottom: '0px'}}>{patient.created_at}</p>
                        </div>
                    )
                )
                :
                (
                    
                    <p style={{marginBottom: '0px'}}>Bislang sind keine Patienten verfügbar.</p>
                )
            }
            <h2 style={{textAlign: 'center', marginTop: '20px'}}>Kerndaten aktuell</h2>
            <div
            className="d-flex justify-content-center align-items-center"
            >
                <button onClick={() => getData('exportkerndatenaktuell')} className="btn btn-link">
                    Kerndaten aktuell exportieren
                </button>
            </div>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{flexWrap: 'wrap', columnGap: '20px'}}
            >
                <p style={{marginBottom: '0px'}}>ID</p>
                <p style={{marginBottom: '0px'}}>Patient-ID</p>
                <p style={{marginBottom: '0px'}}>Vermutete Diagnose</p>
                <p style={{marginBottom: '0px'}}>Histopathologische Untersuchung</p>
                <p style={{marginBottom: '0px'}}>Histopathologie Ergebnis</p>
                <p style={{marginBottom: '0px'}}>Aktuelle Lokaltherapie Sonstiges</p>
                <p style={{marginBottom: '0px'}}>Aktuelle Systemtherapie Sonstiges</p>
                <p style={{marginBottom: '0px'}}>Jucken letzte 24 Stunden</p>
                <p style={{marginBottom: '0px'}}>Created at</p>
            </div>        
            {
                kernAktuell ? kernAktuell.map((kernA, index) => 
                    (
                        <div
                            key={kernA.id}
                            className="d-flex justify-content-center align-items-center"
                            style={{flexWrap: 'wrap', columnGap: '20px'}}
                        >
                            <p style={{marginBottom: '0px'}}>{kernA.id}</p>
                            <p style={{marginBottom: '0px'}}>{kernA.patient_id}</p>
                            <p style={{marginBottom: '0px'}}>{kernA.vermutete_diagnose}</p>
                            <p style={{marginBottom: '0px'}}>{kernA.histopathologische_untersuchung}</p>
                            <p style={{marginBottom: '0px'}}>{kernA.histopathologie_ergebnis}</p>
                            <p style={{marginBottom: '0px'}}>{kernA.aktuelle_lokaltherapie_sonstiges}</p>
                            <p style={{marginBottom: '0px'}}>{kernA.aktuelle_systemtherapie_sonstiges}</p>
                            <p style={{marginBottom: '0px'}}>{kernA.jucken_letzte_24_stunden}</p>
                            <p style={{marginBottom: '0px'}}>{kernA.created_at}</p>
                        </div>
                    )
                )
                :
                (
                    
                    <p style={{marginBottom: '0px'}}>Bislang sind keine Kerndaten aktuell verfügbar.</p>
                )
            }
            <h2 style={{textAlign: 'center', marginTop: '20px'}}>Kerndaten bisher</h2>
            <div
            className="d-flex justify-content-center align-items-center"
            >
                <button onClick={() => getData('exportkerndatenbisher')} className="btn btn-link">
                    Kerndaten bisher exportieren
                </button>
            </div>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{flexWrap: 'wrap', columnGap: '20px'}}
            >
                <p style={{marginBottom: '0px'}}>ID</p>
                <p style={{marginBottom: '0px'}}>Patient-ID</p>
                <p style={{marginBottom: '0px'}}>Bisherige Lokaltherapie Sonstiges</p>
                <p style={{marginBottom: '0px'}}>Bisherige Systemtherapie Sonstiges</p>
                <p style={{marginBottom: '0px'}}>Created at</p>
            </div>
            {
                kernBisher ? kernBisher.map((kernB, index) => 
                    (
                        <div
                            key={index}
                            className="d-flex justify-content-center align-items-center"
                            style={{flexWrap: 'wrap', columnGap: '20px'}}
                        >
                            <p style={{marginBottom: '0px'}}>{kernB.id}</p>
                            <p style={{marginBottom: '0px'}}>{kernB.patient_id}</p>
                            <p style={{marginBottom: '0px'}}>{kernB.bisherige_lokaltherapie_sonstiges}</p>
                            <p style={{marginBottom: '0px'}}>{kernB.bisherige_systemtherapie_sonstiges}</p>
                            <p style={{marginBottom: '0px'}}>{kernB.created_at}</p>
                        </div>
                    )
                )
                :
                (
                    
                    <p style={{marginBottom: '0px'}}>Bislang sind keine Kerndaten bisher verfügbar.</p>
                )
            }
            <h2 style={{textAlign: 'center', marginTop: '20px'}}>Aktuelle lokale Therapien</h2>
            <div
            className="d-flex justify-content-center align-items-center"
            >
                <button onClick={() => getData('exportaktuellelokale')} className="btn btn-link">
                    Aktuelle lokale Therapien exportieren
                </button>
            </div>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{flexWrap: 'wrap', columnGap: '20px'}}
            >
                <p style={{marginBottom: '0px'}}>ID</p>
                <p style={{marginBottom: '0px'}}>Patient-ID</p>
                <p style={{marginBottom: '0px'}}>Therapie-ID</p>
                <p style={{marginBottom: '0px'}}>Created at</p>
            </div>
            {
                aktuellLokal ? aktuellLokal.map((aktuellL, index) => 
                    (
                        <div
                            key={index}
                            className="d-flex justify-content-center align-items-center"
                            style={{flexWrap: 'wrap', columnGap: '20px'}}
                        >
                            <p style={{marginBottom: '0px'}}>{aktuellL.id}</p>
                            <p style={{marginBottom: '0px'}}>{aktuellL.patient_id}</p>
                            <p style={{marginBottom: '0px'}}>{aktuellL.therapie_id}</p>
                            <p style={{marginBottom: '0px'}}>{aktuellL.created_at}</p>
                        </div>
                    )
                )
                :
                (
                    
                    <p style={{marginBottom: '0px'}}>Bislang sind keine aktuellen lokalen Therapien verfügbar.</p>
                )
            }
            <h2 style={{textAlign: 'center', marginTop: '20px'}}>Aktuelle Systemtherapien</h2>
            <div
            className="d-flex justify-content-center align-items-center"
            >
                <button onClick={() => getData('exportaktuellesystem')} className="btn btn-link">
                    Aktuelle Systemtherapien exportieren
                </button>
            </div>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{flexWrap: 'wrap', columnGap: '20px'}}
            >
                <p style={{marginBottom: '0px'}}>ID</p>
                <p style={{marginBottom: '0px'}}>Patient-ID</p>
                <p style={{marginBottom: '0px'}}>Therapie-ID</p>
                <p style={{marginBottom: '0px'}}>Created at</p>
            </div>
            {
                aktuellSystem ? aktuellSystem.map((aktuellS, index) => 
                    (
                        <div
                            key={index}
                            className="d-flex justify-content-center align-items-center"
                            style={{flexWrap: 'wrap', columnGap: '20px'}}
                        >
                            <p style={{marginBottom: '0px'}}>{aktuellS.id}</p>
                            <p style={{marginBottom: '0px'}}>{aktuellS.patient_id}</p>
                            <p style={{marginBottom: '0px'}}>{aktuellS.therapie_id}</p>
                            <p style={{marginBottom: '0px'}}>{aktuellS.created_at}</p>
                        </div>
                    )
                )
                :
                (
                    
                    <p style={{marginBottom: '0px'}}>Bislang sind keine aktuellen Systemtherapien verfügbar.</p>
                )
            }
            <h2 style={{textAlign: 'center', marginTop: '20px'}}>Bisherige lokale Therapien</h2>
            <div
            className="d-flex justify-content-center align-items-center"
            >
                <button onClick={() => getData('exportbisherigelokale')} className="btn btn-link">
                    Bisherige lokale Therapien exportieren
                </button>
            </div>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{flexWrap: 'wrap', columnGap: '20px'}}
            >
                <p style={{marginBottom: '0px'}}>ID</p>
                <p style={{marginBottom: '0px'}}>Patient-ID</p>
                <p style={{marginBottom: '0px'}}>Therapie-ID</p>
                <p style={{marginBottom: '0px'}}>Created at</p>
            </div>
            {
                bisherigLokal ? bisherigLokal.map((bisherigL, index) => 
                    (
                        <div
                            key={index}
                            className="d-flex justify-content-center align-items-center"
                            style={{flexWrap: 'wrap', columnGap: '20px'}}
                        >
                            <p style={{marginBottom: '0px'}}>{bisherigL.id}</p>
                            <p style={{marginBottom: '0px'}}>{bisherigL.patient_id}</p>
                            <p style={{marginBottom: '0px'}}>{bisherigL.therapie_id}</p>
                            <p style={{marginBottom: '0px'}}>{bisherigL.created_at}</p>
                        </div>
                    )
                )
                :
                (
                    
                    <p style={{marginBottom: '0px'}}>Bislang sind keine bisherigen lokalen Therapien verfügbar.</p>
                )
            }
            <h2 style={{textAlign: 'center', marginTop: '20px'}}>Bisherige Systemtherapien</h2>
            <div
            className="d-flex justify-content-center align-items-center"
            >
                <button onClick={() => getData('exportbisherigesystem')} className="btn btn-link">
                    Bisherige Systemtherapien exportieren
                </button>
            </div>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{flexWrap: 'wrap', columnGap: '20px'}}
            >
                <p style={{marginBottom: '0px'}}>ID</p>
                <p style={{marginBottom: '0px'}}>Patient-ID</p>
                <p style={{marginBottom: '0px'}}>Therapie-ID</p>
                <p style={{marginBottom: '0px'}}>Created at</p>
            </div>
            {
                bisherigSystem ? bisherigSystem.map((bisherigS, index) => 
                    (
                        <div
                            key={index}
                            className="d-flex justify-content-center align-items-center"
                            style={{flexWrap: 'wrap', columnGap: '20px'}}
                        >
                            <p style={{marginBottom: '0px'}}>{bisherigS.id}</p>
                            <p style={{marginBottom: '0px'}}>{bisherigS.patient_id}</p>
                            <p style={{marginBottom: '0px'}}>{bisherigS.therapie_id}</p>
                            <p style={{marginBottom: '0px'}}>{bisherigS.created_at}</p>
                        </div>
                    )
                )
                :
                (
                    
                    <p style={{marginBottom: '0px'}}>Bislang sind keine bisherigen Systemtherapien verfügbar.</p>
                )
            }
            <h2 style={{textAlign: 'center', marginTop: '20px'}}>Patientenergebnisse</h2>
            <div
            className="d-flex justify-content-center align-items-center"
            >
                <button onClick={() => getData('exportpatientscores')} className="btn btn-link">
                    Patientenergebnisse exportieren
                </button>
            </div>
            <div
                className="d-flex justify-content-center align-items-center "
                style={{flexWrap: 'wrap', columnGap: '20px'}}
            >
                <p style={{marginBottom: '0px'}}>ID</p>
                <p style={{marginBottom: '0px'}}>Patient-ID</p>
                <p style={{marginBottom: '0px'}}>Criteria 1</p>
                <p style={{marginBottom: '0px'}}>Criteria 2</p>
                <p style={{marginBottom: '0px'}}>Criteria 3</p>
                <p style={{marginBottom: '0px'}}>Criteria 4</p>
                <p style={{marginBottom: '0px'}}>Criteria 5</p>
                <p style={{marginBottom: '0px'}}>Criteria 6</p>
                <p style={{marginBottom: '0px'}}>Criteria 7</p>
                <p style={{marginBottom: '0px'}}>Criteria 8</p>
                <p style={{marginBottom: '0px'}}>Criteria 9</p>
                <p style={{marginBottom: '0px'}}>Criteria 10</p>
                <p style={{marginBottom: '0px'}}>Criteria 11</p>
                <p style={{marginBottom: '0px'}}>Criteria 12</p>
                <p style={{marginBottom: '0px'}}>Criteria 13</p>
                <p style={{marginBottom: '0px'}}>Criteria 14</p>
                <p style={{marginBottom: '0px'}}>Criteria 15</p>
                <p style={{marginBottom: '0px'}}>Criteria 16</p>
                <p style={{marginBottom: '0px'}}>Criteria 17</p>
                <p style={{marginBottom: '0px'}}>Criteria 18</p>
                <p style={{marginBottom: '0px'}}>Criteria 19</p>
                <p style={{marginBottom: '0px'}}>Criteria 20</p>
                <p style={{marginBottom: '0px'}}>Created at</p>
            </div>
            {
                patientScores ? patientScores.map((patientS, index) => 
                    (
                        <div
                            key={index}
                            className="d-flex justify-content-center align-items-center "
                            style={{flexWrap: 'wrap', columnGap: '20px'}}
                        >
                            <p style={{marginBottom: '0px'}}>{patientS.id}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.patient_id}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_1}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_2}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_3}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_4}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_5}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_6}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_7}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_8}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_9}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_10}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_11}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_12}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_13}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_14}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_15}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_16}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_17}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_18}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_19}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.criteria_20}</p>
                            <p style={{marginBottom: '0px'}}>{patientS.created_at}</p>
                        </div>
                    )
                )
                :
                (
                    
                    <p style={{marginBottom: '0px'}}>Bislang sind keine Patientenergebnisse verfügbar.</p>
                )
            }
        </div>
    );
};

export default AdminDashboard;
