import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import NavBar from '../components/Navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

const DashboardPage = () => {
  const [patients, setPatients] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchPatientsAndScores = async () => {
      try {
        const patientsResponse = await axios.get(
          `https://core.ck-care.ch/api/user/${localStorage.getItem(
            'userId'
          )}/patients`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (patientsResponse.data.status === 'success') {
          const scorePromises = patientsResponse.data.data.map(patient =>
            axios
              .get(`https://core.ck-care.ch/api/scores/${patient.id}`, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              })
              .catch(error => ({ error }))
          );

          const scoresResponses = await Promise.all(scorePromises);

          const patientsWithScores = patientsResponse.data.data.map(
            (patient, index) => {
              const scoresResponse = scoresResponses[index];
              if (
                scoresResponse &&
                !scoresResponse.error &&
                scoresResponse.data.status === 'success'
              ) {
                return { ...patient, scores: scoresResponse.data.scores };
              } else {
                return { ...patient, scores: [] };
              }
            }
          );

          setPatients(patientsWithScores);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Patientendaten:', error);
      }
    };

    fetchPatientsAndScores();
  }, [token]);

  const handleDeletePatient = async patientId => {
    try {
      await axios.delete(`https://core.ck-care.ch/api/patient/${patientId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setPatients(patients.filter(patient => patient.id !== patientId));
    } catch (error) {
      console.error('Fehler beim Löschen des Patienten:', error);
    }
  };

  const hasSavedScores = patient => {
    return patient.scores.some(score => score.saved);
  };

  const renderScoreWithIcon = (patientId, score) => {
    if (score.saved) {
      return (
        // TODO: view score
        // This would enable access to view the score
        // <Link to={`/patient/${patientId}/score/${score.id}/display`} className="btn btn-link">
            <span key={score.id} className="me-2">
            {score.total_score} <FontAwesomeIcon icon={faLock} />
            </span>
        // </Link>
    );
    } else {
      return (
        <span key={score.id} className="me-2">
          <Link to={`/patient/${patientId}/score/${score.id}/edit`}>
            {score.total_score} <FontAwesomeIcon icon={faPen} />
          </Link>
        </span>
      );
    }
  };

  const renderScoreCalculationLink = patient => {
    if (patient.scores && patient.scores.length >= 4) {
        return <p
            style={{
                marginBottom: 0
            }}
        >Sie haben die maximale Anzahl von vier möglichen Eingaben für diesen Patienten erreicht. Gerne können Sie einen neuen Patienten anlegen.</p>;
    }
    const scoreDates = [];
    patient.scores.forEach(score => {
        scoreDates.push(Date.parse(score.created_at));
    });
    scoreDates.reverse();
    const now = Date.now();
    const sevenDaysInMilliseconds = 1000 * 60 * 60 * 24 * 7;
    const lastScoreDate = scoreDates[0];
    const lastScoreDatePlusSevenDays = lastScoreDate + sevenDaysInMilliseconds;
    const lastScorePlusAsDate = new Date(lastScoreDatePlusSevenDays).toLocaleString();

    return (
        lastScoreDatePlusSevenDays < now || patient.scores.length === 0 ?
        <Link to={`/patient/${patient.id}/coredata`} className="btn btn-link">
            Score berechnen
        </Link>
        :
        <p
            style={{
                marginBottom: 0
            }}
        >Zwischen den Scores müssen mindestens 7 Tage liegen. Bis zum { lastScorePlusAsDate } können keine weiteren Scores erfasst werden.</p>
        );
  };

  return (
    <>
      <NavBar />
      <div className="container mt-5">
        <h1 className="mb-4">Patientenliste</h1>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Patienten-ID</th>
              <th scope="col">Scores</th>
              <th scope="col" className="align-middle ps-4">
                Aktionen
              </th>
            </tr>
          </thead>
          <tbody>
            {patients.map(patient => (
              <tr key={patient.id}>
                <td className="align-middle">{patient.patient_id}</td>
                <td className="align-middle">
                  {patient.scores && patient.scores.length > 0
                    ? patient.scores.map(score =>
                        renderScoreWithIcon(patient.id, score)
                      )
                    : 'Keine Scores'}
                </td>
                <td className="align-middle">
                  {renderScoreCalculationLink(patient)}
                  {/* TODO: bisher-aktuell: due to new logic with patient data */}
                  {/* <Link
                    to={`/patient/${patient.id}/edit`}
                    className="btn btn-link me-2 "
                  >
                    Patient Editieren
                  </Link> */}
                  {!hasSavedScores(patient) && (
                    <button
                      onClick={() => handleDeletePatient(patient.id)}
                      className="btn btn-link text-danger"
                      style={{ color: 'red' }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Link to="/patient" className="btn btn-link mt-3">
          Patienten registrieren
        </Link>
      </div>
    </>
  );
};

export default DashboardPage;
