import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const PasswordResetPage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        'email': ''
    });
    const [error, setError] = useState('');
    const validateForm = () => {
        if (
            !formData.email
        ) {
            setError('Bitte geben Sie eine E-Mail-Adresse ein.');
            return false;
        }
    
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setError('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
            return false;
        }
        return true;
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setError('');
    };    
    const handleSubmit = async e => {
        console.log('test');
        e.preventDefault();
        console.log('test');
        if (!validateForm()) return;
        try {
            await axios.post(
                'https://core.ck-care.ch/api/auth/user/resetPassword',
                formData
            ).then(response => console.log(response));
            navigate('/');
        } catch (error) {
            setError(
                error.response?.data?.message ||
                'Ein Fehler ist aufgetreten.'
            );
        }
        setError('');
        return true;    
    };
    

    return (
        <div 
            style={{ height: '100vh', width: '500px', maxWidth: '96vw', margin: '0 auto', display: 'grid', alignContent: 'center', justifyContent: 'center' }}>
            <a href="/">Zurück zum Login</a>
            <h1 className="mt-2 text-center">Neues Passwort per E-Mail anfordern</h1>
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}
            <form 
                onSubmit={handleSubmit} 
                style={{ height: 'max-content', width: '500px', maxWidth: '96vw', display: 'grid', alignContent: 'center', justifyContent: 'center' }}
            >
                <label 
                    htmlFor="email"
                    className="mt-2" 
                    style={{ width: '500px', maxWidth: '96vw' }}
                >E-Mail-Adresse</label>
                <input 
                    type="text" 
                    id="email" 
                    name="email"
                    className="form-control mt-2" 
                    style={{ width: '500px', maxWidth: '96vw' }}
                    onChange={handleChange}
                />
                <button type="submit" className="btn btn-link mt-2">Passwort anfordern</button>
            </form>
        </div>
    )
};

export default PasswordResetPage;