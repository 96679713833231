import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import scoreScaleImage from '../assets/score.png';
import NavBar from '../components/Navigation';
import html2canvas from 'html2canvas';

const ScoreDisplay = () => {
  const navigate = useNavigate();
  const { id, score_id } = useParams();
  const [score, setScore] = useState({});
  const [patientId, setPatientId] = useState('');
  const [timestamp, setTimestamp] = useState('');
  const scoreRef = useRef(null);

  useEffect(() => {
    const fetchScoreAndPatientInfo = async () => {
      const token = localStorage.getItem('token');
      try {
        const scoreResponse = await axios.get(
          `https://core.ck-care.ch/api/scores/${id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (scoreResponse.data.status === 'success') {
          const matchedScore = scoreResponse.data.scores.find(
            s => s.id === parseInt(score_id)
          );
          if (matchedScore) {
            setScore(matchedScore);
            const dateParts = matchedScore.created_at.split(' ')[0].split('-');
            setTimestamp(
              `${dateParts[2]}.${dateParts[1]}.${dateParts[0].substring(2)}`
            );
          }

          const patientResponse = await axios.get(
            `https://core.ck-care.ch/api/patient/${id}`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (patientResponse.data.status === 'success') {
            setPatientId(patientResponse.data.patientData.patient_id);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchScoreAndPatientInfo();
  }, [id, score_id]);

  useEffect(() => {
    const takeScreenshot = async () => {
      if (scoreRef.current) {
        try {
          const canvas = await html2canvas(scoreRef.current);
          const blob = await new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Canvas to Blob conversion failed'));
              }
            });
          });
          await sendScreenshotToServer(blob);
        } catch (error) {
          console.error('Screenshot error:', error);
        }
      }
    };

    const sendScreenshotToServer = async blob => {
      const formData = new FormData();
      formData.append('screenshot', blob, 'screenshot.png');
      formData.append('patient_id', id);
      formData.append('timestamp', timestamp);

      const token = localStorage.getItem('token');
      try {
        await axios.post(
          'https://core.ck-care.ch/api/upload-screenshot',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          }
        );

        await axios.get(`https://core.ck-care.ch/api/generate-pdf/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error('Error uploading screenshot or generating PDF:', error);
      }
    };

    if (score && score.total_score !== undefined) {
      takeScreenshot();
    }
  }, [score, id, timestamp]);

  const handleBack = () => {
    navigate('/dashboard');
  };

  const getScorePosition = score => {
    const maxScore = 12.63;
    const scoreOffset = (100 * (parseFloat(score) + maxScore)) / (2 * maxScore);
    return scoreOffset;
  };

  return (
    <>
      <NavBar />
      <div className="container mt-5">
        <h1 className="mb-5">
            Berechneter Score für Patient {patientId} vom {timestamp}
        </h1>
        {score && score.total_score !== undefined ? (
            <div>
                <div ref={scoreRef} style={{ position: 'relative', height: 'max-content' }}>
                    <img
                    src={scoreScaleImage}
                    alt="Score Scale"
                    style={{ width: '100%' }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: '47%',
                            width: '100%',
                            paddingLeft: '3.78%',
                            paddingRight: '7.25%',
                        }}            
                    >
                        <div
                            style={{
                                position: 'relative',
                                left: `${getScorePosition(score.total_score)}%`,
                                width: 'max-content',
                            }}
                        >
                            <div
                                style={{
                                width: '.28vw',
                                height: '4vw',
                                backgroundColor: 'red',
                                }}
                            />
                            <div
                                style={{
                                position: 'absolute',
                                bottom: '4.3vw',
                                transform: 'translateX(-50%)',
                                fontSize: '2.2vw',
                                color: 'red',
                                fontWeight: 'bold',
                                }}
                            >
                                {score.total_score}
                            </div>
                        </div>
                    </div>
                </div>
                <p
                    style={{marginTop: '15px'}}
                >Für Ihren Patienten wurde ein Score von {score.total_score} berechnet. Dies bedeutet, dass ein hohes Risiko für
                    { score.total_score >= -14.76 && score.total_score <= -5.05 &&
                    ( <span> Psoriasis </span> ) }

                    { score.total_score >= -5.04 && score.total_score <= 3.61 &&
                    ( <span> einen Flip-Flop </span> ) }

                    { score.total_score >= 3.62 && score.total_score <= 12.63 &&
                    ( <span> AD </span> ) }
                vorliegt.</p>                
                <p
                    style={{marginBottom: '5px'}}
                >Die Referenzbereiche sind folgende:</p>
                <ul>
                    <li
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '100px max-content',
                            gap: '30px'
                        }}
                    ><span>&#x2022;&nbsp;&nbsp;Psoriasis:</span><span
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '50px 50px 50px',
                            justifyItems: 'center'
                        }}
                    ><span>-14,76</span> <span>bis</span> <span>-5,05</span></span></li>
                    <li
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '100px max-content',
                            gap: '30px'
                        }}
                    ><span>&#x2022;&nbsp;&nbsp;Flip-Flop:</span><span
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '50px 50px 50px',
                            justifyItems: 'center'
                        }}
                    ><span>-5,04</span> <span>bis</span> <span>3,61</span>
                    </span></li>
                    <li
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '100px max-content',
                            gap: '30px'
                        }}
                    ><span>&#x2022;&nbsp;&nbsp;AD:</span><span
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '50px 50px 50px',
                            justifyItems: 'center'
                        }}
                    ><span>3,62</span> <span>bis</span> <span>12,63</span></span></li>
                </ul>                
                <button
                type="button"
                className="btn btn-link mb-3"
                onClick={handleBack}
                >
                    Zurück
                </button>
            </div>
            ) : (
                <p>Loading score...</p>
            )}
        </div>
    </>
  );
};

export default ScoreDisplay;
